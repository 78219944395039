<template>
    <div>
        <el-dialog :title="operation ? '添加' : (optType?'编辑':'查看')" :visible.sync="dlgVisible" v-model="dlgVisible"
                   :close-on-click-modal="false">
            <el-row>
                <el-col :span="12">
                </el-col>
            </el-row>
                <div class="calc-height">
                    <el-table :data="dataList" highlight-current-row height="100%" class="new_table">
                        <el-table-column prop="CreateOn" label="扫码日期" :formatter="formatStartTime"> </el-table-column>
                        <el-table-column prop="SignInCode" label="培训编码"> </el-table-column>
                        <el-table-column prop="USER_NAME" label="签到人"> </el-table-column>
                        <el-table-column prop="Factory" label="公司"> </el-table-column>
                        <el-table-column prop="DptName" label="部门"> </el-table-column>
                        <el-table-column prop="JobName" label="岗位"> </el-table-column>
                    </el-table>
                </div>
                <!-- 分页器 -->
                <el-pagination align="center" @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize"
                               layout="prev, pager, next, jumper,total" :page-count="total" :total="totaldata">
                </el-pagination>
        </el-dialog>
    </div>
</template>

<script>
    import util from "../../../util/date";
    import {
        GetViewSigninInfoPageList
    } from "../../api/hr";
    export default {
        data() {
            return {
                optType: false,   // 编辑/查看
                operation: false, // true:新增, false:编辑
                key: 0,

                dlgVisible: false, // dlg 显示隐藏

                currentPage: 1, // 当前页码
                pageSize: 20, // 每页的数据条数
                total: null, // 总条数
                totaldata: null,
                loading: true,
                page: 1,
                dataList: [], // 列表数据

                docno : "",
            };
        },
        methods: {
            // 选中一行显示
            onShowDlg(pid) {
                if (pid == "" || pid == null) {
                    this.dlgVisible = true;
                }
                else {
                    this.page = 1;
                    this.docno = pid;
                    this.getData();
                }
            },
            formatStartTime: function (row, colume) {
                var date = row[colume.property];
                if (date == undefined) {
                    return "";
                }
                return util.formatDate.format(new Date(date), "yyyy-MM-dd hh:mm:ss");
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.page = val;
                this.getData(false);
            },
            //获取列表
            getData() {
                let para = {
                    docno: this.docno,
                    page: this.page,
                    size: this.pageSize,
                };
                GetViewSigninInfoPageList(para).then((res) => {
                    this.total = res.data.response.pageCount;
                    this.totaldata = res.data.response.dataCount;
                    this.dataList = res.data.response.data;
                    this.loading = false;
                    this.dlgVisible = true;
                });
            },
        },
        mounted() {

        },
    };
</script>

<style scoped>
</style>